import styled from 'styled-components';

const StyledNotFound = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-banner {
    min-height: 400px;
    width: 100%;
    height: 100%;
  }

  .title {
    position: absolute;
    top: 40%;
    width: 100%;
    font-size: 63px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts?.smartNext};
    color: ${({ theme }) => theme.colors?.white};
    ${({ theme }) => theme.bps?.mobile} {
      font-size: 30px;
      padding: 0 15px;
    }
  }
  .nf-button {
    position: absolute;
    top: 50%;
    ${({ theme }) => theme.bps?.mobile} {
      top: 70%;
    }
  }
`;

export default StyledNotFound;
