
'use client';

import propTypes from 'prop-types';
import ResponsiveImage from 'core/image';
import StyledNotFound from './StyledNotFound';
import {buttonItemProps, responsiveMediaItemPropTypes} from '../../../utils/generalProptypes';
import {Button} from '@/UIComponents/Button';

const NotFound = ({title , btn , image}) => {

  return (
    <StyledNotFound>
      <ResponsiveImage {...image} responsive={true} className='image-banner' />
      <h1 className='title'>{title}</h1>
      <div className='nf-button'>
        <Button {...btn} />
      </div>
    </StyledNotFound>
  );
};

NotFound.propTypes = {
  image: propTypes.shape(responsiveMediaItemPropTypes),
  title: propTypes.string,
  btn: propTypes.shape(buttonItemProps),
};

export default NotFound;
